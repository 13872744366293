/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { SliceZone, SEO } from '../components'
import ListingNews from '../components/News/ListingNews'

import { LocaleContext } from '../components/Layout'
import HeroWithImage from '../components/shared/HeroWithImage'
import bubbles from '../../content/images/bubbles.svg'
import Reservation from '../components/Reservation'
import * as S from './styled'

const Post = ({ data: { prismicPost, posts }, location, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const { data } = prismicPost

  const {
    data: {
      title: { text: title },
      image: { url: heroUrlImage },
    },
  } = prismicPost

  return (
    <>
      <SEO
        title={`${title} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale}
        desc={data.description}
        node={prismicPost}
        banner={heroUrlImage}
        article
      />
      <HeroWithImage isPost src={heroUrlImage} />
      <S.Wrapper isPost>
        <S.InnerWrapper>
          <S.Row>
            <S.Col>
              <S.SectionTitle isPost>{title}</S.SectionTitle>
              <S.Date>{data.date}</S.Date>
            </S.Col>
            <S.BubblesPost src={bubbles} />
          </S.Row>
          <S.Row>
            <SliceZone allSlices={data.body} />
            <S.NewsTitle>{i18n.moreNews}</S.NewsTitle>
            <ListingNews posts={posts.edges} />
          </S.Row>
        </S.InnerWrapper>
      </S.Wrapper>
      <Reservation />
    </>
  )
}

export default Post

Post.propTypes = {
  data: PropTypes.shape({
    prismicPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PostBySlug($uid: String!, $locale: String!) {
    prismicPost(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      first_publication_date
      last_publication_date
      data {
        title {
          text
        }
        image {
          url
        }
        description
        date(formatString: "DD.MM.YYYY")
        categories {
          category {
            document {
              data {
                name
              }
            }
          }
        }
        body {
          ... on PrismicPostBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPostBodyDocument {
            slice_type
            id
            primary {
              link {
                url
              }
            }
          }
          ... on PrismicPostBodyImage {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    posts: allPrismicPost(limit: 3, sort: { fields: [data___date], order: DESC }, filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          data {
            image {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            title {
              text
            }
            description
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
